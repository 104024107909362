var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticStyle: { width: "100%" }, attrs: { id: _vm.id } },
    [
      _c(
        "b-button-toolbar",
        { staticClass: "py-1 action-toolbar" },
        [
          _c(
            "b-btn",
            { staticClass: "action-button", on: { click: _vm.selectAll } },
            [_vm._v(_vm._s(_vm.$t("button.selectall")))]
          ),
          _c(
            "b-btn",
            { staticClass: "action-button", on: { click: _vm.selectNone } },
            [_vm._v(_vm._s(_vm.$t("button.selectnone")))]
          ),
        ],
        1
      ),
      _c(
        "b-input-group",
        { staticClass: "mb-2" },
        [
          _c("b-form-input", {
            attrs: { size: "md", placeholder: _vm.$t("dataview.search") },
            on: { input: _vm.applyFilter },
            model: {
              value: _vm.searchFilter,
              callback: function ($$v) {
                _vm.searchFilter = $$v
              },
              expression: "searchFilter",
            },
          }),
          _c(
            "b-input-group-append",
            [
              _c(
                "b-input-group-text",
                [
                  _c("font-awesome-icon", {
                    attrs: { icon: ["far", "magnifying-glass"] },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-balham permission-grid-height checklist-grid",
        staticStyle: { width: "100%" },
        style: [_vm.height != null ? { height: _vm.height + "px" } : {}],
        attrs: {
          id: "contact-grid",
          gridOptions: _vm.gridOptions,
          animateRows: "",
          autoGroupColumnDef: _vm.autoGroupColumnDef,
          columnDefs: _vm.columnDefs,
          context: _vm.context,
          defaultColDef: _vm.defaultColDef,
          noRowsOverlayComponent: "noRowsOverlay",
          noRowsOverlayComponentParams: _vm.noRowsOverlayComponentParams,
          overlayLoadingTemplate: _vm.overlayLoadingTemplate,
          getDataPath: function (data) {
            return data.path
          },
          getRowId: function (params) {
            return params.data.uuId
          },
          groupDefaultExpanded: "",
          rowData: _vm.rowData,
          rowSelection: _vm.multiple ? "multiple" : "single",
          rowMultiSelectWithClick: "",
          sideBar: false,
          suppressDragLeaveHidesColumns: "",
          suppressCellFocus: "",
          suppressContextMenu: "true",
          suppressMultiSort: "",
          suppressRowClickSelection: _vm.readOnly,
          treeData: "",
        },
        on: { "grid-ready": _vm.onGridReady },
      }),
      _c("PropertiesPermissionModal", {
        attrs: {
          show: _vm.showPermissionEdit,
          macros: _vm.macros,
          schema: _vm.schema,
          isAdd: _vm.isPermissionAdd,
          isEdit: _vm.isPermissionEdit,
          entity: _vm.permissionEntity,
          denyRules: _vm.denyRules,
          readOnly: _vm.readOnly,
        },
        on: {
          "update:show": function ($event) {
            _vm.showPermissionEdit = $event
          },
          success: _vm.permissionSelectOk,
        },
      }),
      _c("DataPermissionModal", {
        attrs: {
          show: _vm.showDataPermissionEdit,
          macros: _vm.macros,
          schema: _vm.schema,
          staffRuleApplied: _vm.staffRuleApplied,
          projectRuleApplied: _vm.projectRuleApplied,
          isAdd: _vm.isPermissionAdd,
          isEdit: _vm.isPermissionEdit,
          entity: _vm.permissionEntity,
          dataRules: _vm.dataRules,
          readOnly: _vm.readOnly,
        },
        on: {
          "update:show": function ($event) {
            _vm.showDataPermissionEdit = $event
          },
          success: _vm.dataPermissionSelectOk,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }